<template>
  <div>
    <header-page :title="'Link statuses'" :hasBackLink="false" />

    <div class="filters-container">
      <div class="filters-block">
        <div class="search">
          <input-text v-model="search_text" />
          <div
            v-if="isShowClear"
            class="main-button lg ml-15"
            @click="clearFilters"
          >
            <div>Clear</div>
          </div>
        </div>
        <div class="button">
          <div v-if="false" class="main-button lg orange" @click="setFilters">
            <div class="icon" v-html="Filter"></div>
            <div>Filters</div>
          </div>
        </div>
      </div>
      <div class="selected-filters" v-if="selectedFilters.length">
        <div v-for="item in selectedFilters" :key="item.text">
          <div class="icon" @click="removeFilter(item.id)" v-html="Cross"></div>
          <div class="text">{{ item.text }}</div>
        </div>
      </div>
    </div>

    <grid
      v-model:columns="columns"
      v-model="rows"
      :options="options"
      :page="page"
      :per_page="per_page"
      :newRowComponent="newRowComponent"
      :isHideNewRowComponent="isHideNewRowComponent"
      :countRows="countRows"
      :search_text="search_textToGrid"
      @deleteRow="deleteRow"
      @nextPage="nextPage"
      @prevPage="prevPage"
      @saveNewRow="saveNewLink"
      @saveRow="saveLink"
      @setPerPage="setPerPage"
      @sortRows="fetchData"
    />
    <set-filters v-if="isVisibleFilters" v-model="isVisibleFilters" />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import NewRowLinkStatus from '@/components/NewRows/NewRowLinkStatus'
import { fetchData, urls } from '@/utils/urls.js'
import { hasAccess, permissions } from '@/utils/permissions'
import { listMixin } from '@/utils/list-mixin'
import { GrigOptions } from '@/models/grid-options'
import { GridColumn } from '@/models/grid-column'

export default {
  name: 'LinkStatuses',
  mixins: [listMixin],

  data() {
    let colsKeyUrlIndex = 0
    return {
      options: new GrigOptions({
        isSelectable: false,
        isEditable: hasAccess(permissions.update_link_status),
        isAdd: hasAccess(permissions.create_link_status),
        isDelete: hasAccess(permissions.delete_link_status),
        addButtonText: 'Add link status',
        getDeleteContent: async (rows) => {
          if (!rows) return ``
          let query = `page=1&per_page=all`
          let links = await fetchData(
            urls.links,
            'GET',
            null,
            query + `&link_status_f=` + rows[0].link_status
          )
          let count = links?.count || 0

          if (!count) {
            this.options.deleteModalOptions.hideYes = false
            return `<div class="mb-10">Are you sure you want to delete link status?</div>`
          } else {
            this.options.deleteModalOptions.hideYes = true
            return `<div class="mb-10">
              You can’t delete the statuse till it’s assigned to existing links.
                ${links.rows.map((item) => `<div>• ${item.url}</div>`).join('')}
              </div>`
          }
        },
      }),

      columns: [
        new GridColumn({
          keyUrl: 's' + colsKeyUrlIndex++,
          key: 'link_status',
          text: 'Link status',
        }),
      ],
      newLink: {
        link_status: null,
      },
      newRowComponent: shallowRef(NewRowLinkStatus),
    }
  },

  created() {
    this.setDataFromQueryParams() // 3
    this.fetchData()
  },

  methods: {
    saveNewLink(link) {
      this.setNewLink(link)
      this.addRow()
    },

    saveLink(link) {
      this.editRow(link)
    },

    newLinkToDefault() {
      for (let key in this.newLink) {
        this.newLink[key] = null
      }
    },

    setNewLink(link) {
      for (let key in link) {
        this.newLink[key] = link[key]
      }
    },

    async editRow(link) {
      this.$store.commit('setIsLoading', true)
      const id = link.id
      delete link.id

      let result = await fetchData(
        urls.linkStatuses + `/${id}`,
        'POST',
        this.bodyToSave(link)
      )
      if (!result?.detail) {
        let linkRow = this.rows.find((item) => item.id === id)
        for (const key in link) {
          if (key in linkRow) linkRow[key] = link[key]
        }

        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async addRow() {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(
        urls.linkStatusesCreate,
        'POST',
        this.bodyToSave(this.newLink)
      )
      if (!result?.detail) {
        const tmpLink = this.newLink
        this.rows.unshift({ ...{ id: result.id }, ...tmpLink })
        this.isHideNewRowComponent = true
        this.$nextTick(() => {
          this.isHideNewRowComponent = false
        })

        this.newLinkToDefault()
      } else {
        this.isHideNewRowComponent = false
        console.error(result.detail)
      }

      this.$store.commit('setIsLoading', false)
    },

    async deleteRow(id) {
      this.$store.commit('setIsLoading', true)

      let result = await fetchData(urls.linkStatuses + `/${id}`, 'DELETE')
      if (!result?.detail) {
        const idx = this.rows.findIndex((item) => item.id === id)
        this.rows.splice(idx, 1)
      } else console.error(result.detail)

      this.$store.commit('setIsLoading', false)
    },

    async fetchData() {
      this.setQueryParams()

      if (this.isLoading) return

      this.$store.commit('setIsLoading', true)
      let query = `page=${this.page}&per_page=${this.per_page}`
      let prevSearch_text = this.search_text.value
      if (this.search_text.value?.length > 2) {
        query += `&search_text=${this.search_text.value}`
      }

      query += this.queryToSort()

      let result = await fetchData(urls.linkStatuses, 'GET', null, query)

      this.rows = (result?.rows || []).map((item) => {
        return {
          id: item.id,
          link_status: item.link_status,
        }
      })

      this.countRows = result?.count || 0
      this.search_textToGrid = this.search_text.value
      this.$store.commit('setIsLoading', false)

      if (
        this.search_text.value?.length > 2 &&
        prevSearch_text !== this.search_text.value
      )
        this.fetchData()
    },

    setFilters() {
      this.isVisibleFilters = !this.isVisibleFilters
    },
  },
}
</script>
